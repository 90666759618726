import React from 'react';
import { SnackbarProvider } from 'notistack';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material';
import { IconContext } from 'phosphor-react';
import theme from '~core/theme';
import defaultPhosphorIconValue from '~core/phosphor-icons.config';

interface IProps {
  children: JSX.Element;
}
function AppUiProvider({ children }: IProps) {
  return (
    <SnackbarProvider>
      <>
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <IconContext.Provider value={defaultPhosphorIconValue}>
            {children}
          </IconContext.Provider>
        </ThemeProvider>
      </>
    </SnackbarProvider>
  );
}

export default AppUiProvider;
