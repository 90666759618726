import React from 'react';
import CheckIcon from '@mui/icons-material/Check';

const checkboxStyle = {
  height: 18,
  width: 18,
  margin: 3,
  backgroundColor: '#EBEBEB',
  borderRadius: 5,
};

function CheckboxIcon() {
  return <div style={checkboxStyle} />;
}

function CheckedCheckboxIcon() {
  return (
    <div
      style={{
        ...checkboxStyle,
        backgroundColor: '#CE6F3A',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CheckIcon color="secondary" sx={{ width: 18, height: 18 }} />
    </div>
  );
}

export default {
  defaultProps: {
    icon: <CheckboxIcon />,
    checkedIcon: <CheckedCheckboxIcon />,
  },
};
