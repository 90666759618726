import { createTheme, Theme } from '@mui/material';
import MuiCheckbox from './components/MuiCheckbox';

export const colors = {
  orange: '#CE6F3A',
  lightOrange: '#e9bf97',
  // TODO change #121126 to the same name with scss
  black: '#121126',
  white: '#ffffff',
  paper: '#f6f6f4',
  green: '#2D964A',
  red: '#E44240',
  grey: '#E0E0E0',
  darkGrey: '#828282',
  darkerBlue: '#1b1d2b',
  mapFill: '#102553',
  crossSectionPointsFill: '#4f4744',
  csCanvas: {
    rullers: '#141637',
    surface: '#232446',
    issueColor: '#fc0360',
    rullersText: '#81859a',
    green: '#00ed81',
    blue: '#3070ff',
    red: '#fc0360',
    yellow: '#f5c345',
    orange: '#e17432',
    layers: [
      '#6d5c5a',
      '#634944',
      '#754c41',
      '#8e4730',
      '#7a351a',
      '#562e1c',
    ],
  },
};

/**
 * Theme Configurations @see https://mui.com/material-ui/api
 */

const theme: Theme = createTheme({
  palette: {
    primary: {
      main: colors.orange,
      contrastText: colors.black,
    },
    info: {
      main: colors.black,
      contrastText: colors.white,
    },
    secondary: {
      main: colors.black,
      contrastText: colors.black,
    },
    background: {
      default: colors.white,
      paper: colors.paper,
    },
    success: {
      main: colors.green,
    },
    error: {
      main: colors.red,
    },
    text: {
      primary: colors.black,
    },
  },
  components: {
    MuiBadge: {
      styleOverrides: {
        root: {
          svg: {
            margin: 0,
          },
          '& .BaseBadge-badge': {
            height: '16px',
            minWidth: '16px',
            padding: '0 1px',
            fontSize: '10px',
            color: 'white',
            border: '2px solid white',
            boxShadow: `0px 0px 0.5px 0px ${colors.orange}`,
            top: '2px',
            right: '4px',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderRadius: '10px',
          backgroundColor: 'white',
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          '&.dialog-backdrop': {
            backgroundColor: 'rgba(27, 29, 43, 30%)',
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          '&.dialog-backdrop .MuiBackdrop-root': {
            backgroundColor: 'rgba(27, 29, 43, 30%)',
          },
        },
      },
    },
    MuiDialog: {
      defaultProps: {
        componentsProps: {
          backdrop: {
            className: 'dialog-backdrop',
          },
        },
      },
      styleOverrides: {
        root: {
          '& .MuiDialog-paper': {
            maxHeight: '100vh',
          },
        },
      },
    },
    MuiCheckbox,
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          paddingLeft: '25px',
          '&.MuiInputBase-inputAdornedStart': {
            paddingLeft: '0px',
          },
        },
        icon: {
          top: 'calc(50% - 12px)',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
      styleOverrides: {
        root: {
          borderRadius: '10px',
          textTransform: 'none',
          height: '50px',
          fontWeight: 'bold',
          fontSize: '1rem',
          padding: '6px 42px !important',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        text: {

        },
      },
    },
    MuiAppBar: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: colors.paper,
          marginTop: '1rem',
          borderRadius: '10px',
          width: '326px',
          paddingLeft: '0 !important',
          paddingRight: '0 !important',
          '&.MuiInputBase-fullWidth': {
            width: '100%',
          },
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        notched: false,
      },
      styleOverrides: {
        root: {
          borderRadius: '10px',
          height: '50px',
          paddingLeft: '11px',
          paddingRight: '11px',
          '& input': {
            borderRadius: '10px',
            padding: '14.5px 25px',
          },
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: colors.black,
            },
          },
          '&.Mui-error': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: colors.red,
            },
          },

        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& label': {
            marginLeft: '25px',
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {

        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: true,
        disableAnimation: true,
      },
      styleOverrides: {
        root: {
          color: colors.darkGrey,
          fontWeight: 600,
          fontSize: '14px',
          transform: 'none',
          marginBottom: '-.7rem',
          position: 'relative',
          '&.Mui-focused': {
            color: colors.darkGrey,
          },
        },
        asterisk: {
          color: colors.red,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          position: 'absolute', // TODO: check this
          right: '-.5rem',
          zIndex: 2,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          margin: 10,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: colors.orange,
          fontSize: '14px',
          fontWeight: 600,
          borderRadius: '9px',
          padding: '10px 18px',
          backgroundColor: colors.white,
          '&.canvas-tooltip': {
            color: colors.csCanvas.rullersText,
            borderRadius: '5px',
            backgroundColor: colors.csCanvas.rullers,
          },
        },
        arrow: {
          color: colors.white,
          '&.canvas-tooltip': {
            color: colors.csCanvas.rullers,
          },

        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          background: colors.white,
          '&::before': {
            display: 'none',
          },
          '&.Mui-expanded': {
            background: colors.paper,
            margin: 0,
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          flexDirection: 'row',
          paddingLeft: '26px',
          borderTop: '1px solid #E5E5E5',
          borderBottom: `1px solid ${colors.paper}`,
          maxHeight: '62px',
          minHeight: '62px',
          background: colors.paper,
          '&.Mui-expanded': {
            borderBottom: '1px solid #E5E5E5',
          },
          '& .MuiAccordionSummary-content': {
            margin: '11px 0 !important',
            '&.Mui-expanded': {
              margin: '11px 0 !important',
            },
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '16px 24px 24px 24px',
        },
      },
    },
    MuiAccordionActions: {
      styleOverrides: {
        root: {
          justifyContent: 'start',
        },
      },
    },
  },
  typography: {
    h1: {
      fontWeight: 'bold',
    },
    h2: {
      fontSize: 24,
      fontFamily: "'Inter', sans-serif",
      fontWeight: 'bold',
    },
    h3: {
      fontWeight: 'bold',
    },
    h4: {
      fontWeight: 'bold',
    },
    h5: {
      fontWeight: 'bold',
    },
    subtitle2: {
      fontWeight: 400,
    },
    fontFamily: "'Inter', sans-serif",
  },

});

export default theme;
