import React from 'react';
import Loadable from 'react-loadable';
import CircularLoaderFullHeight from '~components/CircularLoaderFullHeight';
import AppUiProvider from '~core/AppUiProvider';

const AppStateLayer = Loadable({
  loader: () => import('./core/AppStateLayer'),
  loading: () => <CircularLoaderFullHeight />,
});

function App() {
  return (
    <AppUiProvider>
      <AppStateLayer />
    </AppUiProvider>
  );
}

export default App;
